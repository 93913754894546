<script setup lang="ts">
    import type { CarouselCardData } from '~/@types/cms';

    export interface CarouselSlideProps {
        slide: CarouselCardData;
        textColor: string;
        backgroundColor: string;
        isActive: boolean;
        isDuplicate?: boolean;
    }
    const props = defineProps<CarouselSlideProps>();

    const isDarkBackground = computed(() => {
        return props.backgroundColor === 'black';
    });
</script>
<template>
    <div :class="['org-carousel-slide-content m-auto flex h-full flex-col', { 'bg-[#FF0000]': isDuplicate }]">
        <div :class="['flex h-full shrink items-center justify-center', { 'image-container': isActive }]">
            <lazy-atm-image
                v-if="slide.image"
                class="aspect-3/2 max-h-full"
                provider="assets"
                :sizes="nuxtImageSizes.carousel"
                :src="slide.image" />
        </div>

        <div :class="['flex shrink-0 flex-col text-center', { 'bike-info': isActive, 'opacity-0': !isActive }]">
            <div
                v-if="slide.title"
                :class="{ textColor }"
                v-html="woomTextFormat(slide.title)"></div>

            <div
                v-if="slide.subtitle"
                class="m-auto max-w-sm"
                v-html="woomTextFormat(slide.subtitle)"></div>
            <div class="m-auto flex w-full max-w-sm items-center justify-center gap-2 py-2 max-md:flex-col">
                <lazy-atm-link
                    v-if="slide.link"
                    :link="slide.link"
                    :styling="isDarkBackground ? 'solid-invert' : 'solid-secondary'" />

                <lazy-atm-link
                    v-if="slide.link2"
                    :link="slide.link2"
                    :styling="isDarkBackground ? 'outline-invert' : 'outline'" />
            </div>
        </div>
    </div>
</template>
<style scoped>
    .org-carousel-slide-content {
        --bike-info: 10.25rem;
    }
    @screen md {
        .image-container {
            max-height: calc(100% - var(--bike-info));
        }
        .bike-info {
            height: var(--bike-info);
        }
    }
</style>
